import { OperationMethod, processFetchOperation } from '../../api';
import { toDomain } from './PetMapper';
import { Pet } from '../../../domain/entities/Pet/Pet';
import { PetRepository } from '../../../domain/repositories/PetRepository';

export class PetNomaRepository implements PetRepository {
    findByPolicyNumber(policyNumber: string): Promise<Pet | undefined> {
        return processFetchOperation(`/v1/resources/pets`, OperationMethod.GET, { params: { policyNumber } })
            .then((dto) => toDomain(dto))
            .catch(() => undefined);
    }
}
