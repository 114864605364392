import { TemplateInputType } from '../../../domain/values/TemplateInputType';
import { Button, ButtonVariant, Input, MoreIcon, TrashIcon } from '../../atoms';
import './template-input.modules.scss';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import { formatSnakeCaseToReadable } from '../../utils/StringUtils';
import { useEffect, useState } from 'react';

export interface TemplateInputProps {
    tagId: string;
    inputType: TemplateInputType;
    listable?: boolean;
    countryCode: string;
    onUpdateTemplateInput: (combinedValue: string) => void;
}

const TemplateInput = ({
    tagId,
    inputType,
    listable = false,
    countryCode,
    onUpdateTemplateInput,
}: TemplateInputProps) => {
    const { t } = useModuleTranslation();
    const [lines, setLines] = useState<number[]>([0]);
    const [inputValues, setInputValues] = useState<{ [key: number]: { first: string; second?: string } }>({});
    const isDateRange = inputType === TemplateInputType.DATE_RANGE;

    useEffect(() => {
        updateCombinedValue();
    }, [inputValues]);

    const getLineValue = (line: number, position: string) => {
        if (inputValues[line] && inputValues[line][position]) return inputValues[line][position];
        else return '';
    };

    const updateCombinedValue = () => {
        const combinedValue = Object.values(inputValues)
            .map((line) => {
                const { first, second } = line;
                if (first && second) {
                    return `${t(`from`, {
                        ns: 'common',
                        lng: countryCode.toLowerCase(),
                    })} ${first} ${t(`to`, {
                        ns: 'common',
                        lng: countryCode.toLowerCase(),
                    })} ${second}`;
                }
                if (first) {
                    return first;
                }
                return '';
            })
            .filter(Boolean)
            .join(', ');
        onUpdateTemplateInput(combinedValue);
    };
    const addLine = () => {
        setLines((prevLines) => [...prevLines, prevLines.length]);
    };

    const removeLine = (lineIndex: number) => {
        setLines((prevLines) => prevLines.filter((index) => index !== lineIndex));
        setInputValues((prevInputValues) => {
            const updatedValues = { ...prevInputValues };
            delete updatedValues[lineIndex];
            return updatedValues;
        });
    };

    const updateLineValue = (lineIndex: number, field: string, newValue: string) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [lineIndex]: {
                ...prevValues[lineIndex],
                [field]: newValue,
            },
        }));
    };

    return (
        <div className={`template-input-container ${isDateRange ? '' : 'single-input'}`}>
            {lines.map((lineIndex, idx) => (
                <div key={lineIndex} className="input-line">
                    <Input
                        className="input1"
                        value={getLineValue(lineIndex, 'first')}
                        onChange={(e) => updateLineValue(lineIndex, 'first', e)}
                        label={isDateRange ? t(`template_input.from`) : formatSnakeCaseToReadable(tagId, true)}
                    />
                    {isDateRange && (
                        <Input
                            className="input2"
                            value={getLineValue(lineIndex, 'second')}
                            onChange={(e) => updateLineValue(lineIndex, 'second', e)}
                            label={t(`template_input.to`)}
                        />
                    )}
                    {listable && (
                        <>
                            {idx === lines.length - 1 ? (
                                <Button
                                    className="button"
                                    icon={MoreIcon}
                                    text={`${t(`template_input.add`)} ${formatSnakeCaseToReadable(tagId)}`}
                                    onClick={addLine}
                                />
                            ) : (
                                <Button
                                    className="button delete-button"
                                    icon={TrashIcon}
                                    text={t(`template_input.delete`)}
                                    onClick={() => removeLine(lineIndex)}
                                    variantName={ButtonVariant.DANGER}
                                />
                            )}
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TemplateInput;
