import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './claim-requests-list.modules.scss';
import { ClaimRequest } from '../../../domain/entities/ClaimRequest';
import { useState, useEffect } from 'react';
import { Button, ButtonVariant } from '../../atoms/button/Button';
import { Pet } from '../../../domain/entities/Pet/Pet';
import Avatar from '../../atoms/avatar/Avatar';
export interface ClaimRequestsListProps {
    claimRequests: ClaimRequest[];
    className?: string;
    goToDetail: (claimRequestKey: string) => void;
    getPetInformation?: (policyIdentifier: string) => Promise<Pet>;
}

const DEFAULT_DATE_FORMAT_OPTIONS = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
} as const;
const dateTimeFormat = Intl.DateTimeFormat('es', DEFAULT_DATE_FORMAT_OPTIONS);

export default function ClaimsRequestsList({
    claimRequests,
    className = '',
    goToDetail,
    getPetInformation,
}: ClaimRequestsListProps) {
    const { t } = useModuleTranslation();
    const [petInfoList, setPetInfoList] = useState<{ claimKey: string; pet?: Pet }[]>([]);

    useEffect(() => {
        const fetchPetInfo = async () => {
            if (!getPetInformation) return;

            const updatedPetInfoList: { claimKey: string; pet?: Pet }[] = [];

            for (const claimRequest of claimRequests) {
                const claimIdentifier = claimRequest.key;

                try {
                    const pet = await getPetInformation(claimRequest.policyIdentifier);
                    updatedPetInfoList.push({ claimKey: claimIdentifier, pet });
                } catch (error) {
                    updatedPetInfoList.push({ claimKey: claimIdentifier, pet: undefined });
                }
            }
            setPetInfoList(updatedPetInfoList);
        };

        fetchPetInfo();
    }, [claimRequests, getPetInformation]); // Dependemos de claimRequests y getPetInformation

    return (
        <table className={`claim-request-list ${className}`}>
            <thead>
                <tr>
                    <th>{t('claim_request_list.table.headers.pet')}</th>
                    <th>{t('claim_request_list.table.headers.petParentEmail')}</th>
                    <th>{t('claim_request_list.table.headers.policyIdentifier')}</th>
                    <th>{t('claim_request_list.table.headers.creationDate')}</th>
                    <th>{t('claim_request_list.table.headers.updateDate')}</th>
                    <th>{t('claim_request_list.table.headers.link')}</th>
                </tr>
            </thead>
            <tbody>
                {claimRequests.map((claimRequest, index) => {
                    const petEntry = petInfoList.find((item) => item.claimKey === claimRequest.key);
                    const pet: Pet | undefined = petEntry?.pet;

                    return (
                        <tr key={index}>
                            <td>
                                {pet && (
                                    <div className={'pet-information'}>
                                        <Avatar
                                            className="pet-avatar"
                                            src={pet.photo}
                                            alt={`${pet.name} profile picture`}
                                        />
                                        <span>{`${pet.name}`}</span>
                                    </div>
                                )}
                            </td>
                            <td>{pet && <span>{`${pet.petParent.email}`}</span>}</td>
                            <td>{claimRequest.policyIdentifier}</td>
                            <td>{dateTimeFormat.format(claimRequest.createdAt)}</td>
                            <td>
                                {claimRequest.updatedAt
                                    ? dateTimeFormat.format(claimRequest.updatedAt)
                                    : dateTimeFormat.format(claimRequest.createdAt)}
                            </td>
                            <td>
                                <Button
                                    variantName={ButtonVariant.BASIC_INFO}
                                    onClick={() => goToDetail(claimRequest.key)}
                                    text={t('claim_request_list.table.body.link')}
                                ></Button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
