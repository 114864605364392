import { TemplateInputType } from './TemplateInputType';

export class TemplateTag {
    id: string;
    type: TemplateInputType;
    isList: boolean;

    static tagPattern = /\{\{([^}]+)\}\}/g;

    constructor(id: string, type: TemplateInputType, isList: boolean) {
        this.id = id;
        this.type = type;
        this.isList = isList;
    }

    static parseTagContent(content: string): TemplateTag {
        const [id, type, isList] = content.split(';'); // Dividimos el contenido por `;`
        return new TemplateTag(id.trim(), type.trim() as TemplateInputType, isList ? isList.trim() === 'true' : false);
    }

    static hasTags(text: string): boolean {
        return text.search(this.tagPattern) !== -1;
    }

    static extractTags(text: string): TemplateTag[] {
        const tags: TemplateTag[] = [];
        let match: RegExpExecArray | null;

        TemplateTag.tagPattern.lastIndex = 0;

        while ((match = TemplateTag.tagPattern.exec(text)) !== null) {
            const tagContent = match[1];
            try {
                const tagData = TemplateTag.parseTagContent(tagContent);
                tags.push(tagData);
            } catch (error) {
                console.error(error);
            }
        }

        return tags;
    }
}
