import { useEffect } from 'react';
import { GetOnHoldClaimRequests } from '../../../../application/ClaimRequest/GetOnHoldClaimRequests';
import { useAsync } from '../../../../domain/utils';
import { ClaimRequestNomaRepository } from '../../../../infraestructure/repositories/claimRequest/ClaimRequestNomaRepository';
import { ClaimRequest } from '../../../../domain/entities';

const getOnHoldClaimRequestsList = new GetOnHoldClaimRequests(ClaimRequestNomaRepository);
export const useGetOnHoldClaimRequest = (countryCode: string) => {
    const { execute, status, value } = useAsync<ClaimRequest[] | undefined>(getOnHoldClaimRequestsList);

    useEffect(() => {
        execute(countryCode);
    }, []);

    return { onHoldClaimRequest: value, onHoldClaimRequestFetchStatus: status };
};
