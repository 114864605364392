import { useEffect } from 'react';
import { SupportingDocumentDeletionReason, useAsync, PetNomaRepository } from '@barkibu/noma-commons';
import { CompleteDataEntry } from '../../../application/CompleteDataEntry/CompleteDataEntry';
import { CreateIncidentSupportingDocument } from '../../../application/CreateIncidentSupportingDocument/CreateIncidentSupportingDocument';
import { DeleteIncidentSupportingDocument } from '../../../application/DeleteIncidentSupportingDocument/DeleteIncidentSupportingDocument';
import { FindIncidentDetails } from '../../../application/FindIncidentDetails/FindIncidentDetails';
import { UpdateIncidentSupportingDocumentIsNewlyAddedByParent } from '../../../application/UpdateIncidentSupportingDocumentIsNewlyAddedByParent/UpdateIncidentSupportingDocumentIsNewlyAddedByParent';
import { UpdateIncidentSupportingDocument } from '../../../application/UpdateIncidentSupportingDocuments/UpdateIncidentSupportingDocuments';
import { IncidentDetails } from '../../../domain/entities/IncidentDetails';
import { SupportingDocument } from '../../../domain/entities/supporting-document';
import { IncidentNomaRepository } from '../../../infraestructure/repositories/incident/IncidentNomaRepository';
import { CreateSupportingDocumentDto } from '../../../infraestructure/repositories/supporting_document/dtos/CreateSupportingDocumentDto';
import { UpdateIsNewlyAddedByParentDto } from '../../../infraestructure/repositories/supporting_document/dtos/UpdateIsNewlyAddedByParentDto';
import { UpdateSupportingDocumentDto } from '../../../infraestructure/repositories/supporting_document/dtos/UpdateSupportingDocumentDto';
import { SupportingDocumentNomaRepository } from '../../../infraestructure/repositories/supporting_document/SupportingDocumentRepository';

const incidentRepository = new IncidentNomaRepository();
const supportingDocumentNomaRepository = new SupportingDocumentNomaRepository();

const findIncidentDetails = new FindIncidentDetails(incidentRepository, new PetNomaRepository());

const createIncidentSupportingDocument = new CreateIncidentSupportingDocument(supportingDocumentNomaRepository);
const updateIncidentSupportingDocument = new UpdateIncidentSupportingDocument(supportingDocumentNomaRepository);
const updateIncidentSupportingDocumentIsNewlyAddedByParent = new UpdateIncidentSupportingDocumentIsNewlyAddedByParent(
    supportingDocumentNomaRepository
);
const deleteIncidentSupportingDocument = new DeleteIncidentSupportingDocument(supportingDocumentNomaRepository);
const completeDataEntry = new CompleteDataEntry(incidentRepository);
export const useIncidentDetails = (claimRequestKey?: string) => {
    const { execute, status, value, setValue } = useAsync<IncidentDetails | undefined>(findIncidentDetails);

    useEffect(() => {
        if (claimRequestKey != undefined) execute(claimRequestKey);
        else setValue(new IncidentDetails());
    }, [claimRequestKey]);

    const createSupportingDocument = async (key: string, document: CreateSupportingDocumentDto) => {
        const createdDocumentResult = await createIncidentSupportingDocument.execute(key, document);
        setValue((incidentDetails) => {
            const updatedList = incidentDetails?.supportingDocuments ? incidentDetails?.supportingDocuments : [];
            updatedList.push(createdDocumentResult.supportingDocument);
            return incidentDetails?.updateSupportingDocuments(updatedList);
        });
    };

    const updateSupportingDocument = async (key: string, document: UpdateSupportingDocumentDto) => {
        const updatedDocumentResult = await updateIncidentSupportingDocument.execute(key, document);
        setValue((incidentDetails) => {
            const updatedList = incidentDetails?.supportingDocuments?.map((el) => {
                if (el.key == updatedDocumentResult.supportingDocument.key) {
                    return updatedDocumentResult.supportingDocument;
                }
                return el;
            });
            return incidentDetails?.updateSupportingDocuments(updatedList ? updatedList : []);
        });
    };

    const updateSupportingDocumentIsNewlyAddedByParent = async (
        key: string,
        document: UpdateIsNewlyAddedByParentDto
    ) => {
        const updatedDocumentResult = await updateIncidentSupportingDocumentIsNewlyAddedByParent.execute(key, document);
        setValue((incidentDetails) => {
            const updatedList = incidentDetails?.supportingDocuments?.map((el) => {
                if (el.key == updatedDocumentResult.supportingDocument.key) {
                    return updatedDocumentResult.supportingDocument;
                }
                return el;
            });
            return incidentDetails?.updateSupportingDocuments(updatedList ? updatedList : []);
        });
    };

    const deleteSupportingDocument = async (
        key: string,
        document: SupportingDocument,
        deletionReason: SupportingDocumentDeletionReason
    ) => {
        await deleteIncidentSupportingDocument.execute(document.key!, deletionReason);
        setValue((incidentDetails) => {
            const updatedList = incidentDetails?.supportingDocuments?.filter((el) => {
                if (el.key != document.key) {
                    return el;
                }
            });
            return incidentDetails?.updateSupportingDocuments(updatedList ? updatedList : []);
        });
    };

    const completeDataEntryFunction = async (releaseClaimRequest: boolean) => {
        await completeDataEntry.execute(claimRequestKey!, releaseClaimRequest);
    };

    return {
        incidentDetails: value,
        incidentDetailsFetchStatus: status,
        createSupportingDocument,
        updateSupportingDocument,
        updateSupportingDocumentIsNewlyAddedByParent,
        deleteSupportingDocument,
        completeDataEntry: completeDataEntryFunction,
    };
};
