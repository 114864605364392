import { UseCase, PetRepository } from '@barkibu/noma-commons';
import { IncidentClaimRequest } from '../../domain/entities/IncidentClaimRequest';
import { InvoicesDetails } from '../../domain/entities/InvoicesDetails';
import { IncidentRepository } from '../../domain/repositories/IncidentRepository';
import { InvoiceRepository } from '../../domain/repositories/InvoiceRepository';

export class FindIncidentInvoicesDetails implements UseCase<InvoicesDetails> {
    constructor(
        private invoiceRepository: InvoiceRepository,
        private incidentRepository: IncidentRepository,
        private petRepository: PetRepository
    ) {}

    async execute(claimRequestKey: IncidentClaimRequest['key']): Promise<InvoicesDetails> {
        try {
            const incidentClaimRequest = await this.incidentRepository.findIncidentClaimRequest(claimRequestKey);
            const createdInvoices = await this.invoiceRepository.findByIncidentKey(incidentClaimRequest.incident?.key!);
            const pet = incidentClaimRequest.policyIdentifier
                ? await this.petRepository.findByPolicyNumber(incidentClaimRequest.policyIdentifier)
                : undefined;

            if (!pet) {
                throw new Error(
                    `Cannot properly Fetch Incident Invoice as missing Pet/Policy Information ${claimRequestKey}`
                );
            }
            return new InvoicesDetails(createdInvoices, pet);
        } catch (e) {
            throw new Error('Invoice not found');
        }
    }
}
