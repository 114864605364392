import { Contract } from '../../../domain/entities/Contract';
import { Policy } from '../../../domain/entities/Policy';
import { Pet } from '../../../domain/entities/Pet/Pet';
import { PetGender } from '../../../domain/values/PetGender';
import { config } from '../../config/config';
import { FindPetDto } from './dtos/FindPetDto';
export const toDomain = (el: FindPetDto): Pet => {
    return new Pet({
        name: el.name,
        chip: el.chip,
        birth: new Date(el.birthDate),
        gender: el.sex.toLowerCase() === 'male' ? PetGender.Male : PetGender.Female,
        breed: el.breed,
        petParent: {
            email: el.petParentEmail,
        },
        policies: el.policies.map(
            (policy) =>
                new Policy(
                    policy.product,
                    policy.status,
                    policy.policyNumber,
                    new Date(policy.startDate),
                    new Date(policy.expirationDate),
                    policy.claimRequestStats,
                    policy.contract.map(
                        (contract): Contract => ({
                            identifier: contract.identifier,
                            startDate: new Date(contract.startDate),
                            expirationDate: new Date(contract.expirationDate),
                            productVersionTag: contract.productVersionTag,
                            status: contract.status,
                        })
                    )
                )
        ),
        photo: config.APP_BACKEND_BASE_URL ? `${config.APP_BACKEND_BASE_URL}/v2/pets/${el.key}/avatar` : undefined,
    });
};
