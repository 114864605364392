import { ClaimRequest } from '../../domain/entities';
import { ClaimRequestRepository } from '../../domain/repositories';
import { UseCase } from '../../domain/utils';
export class GetOnHoldClaimRequests implements UseCase<ClaimRequest[]> {
    private claimRequestRepository: ClaimRequestRepository;
    constructor(claimRequestRepository: ClaimRequestRepository) {
        this.claimRequestRepository = claimRequestRepository;
    }
    async execute(countryCode: String): Promise<ClaimRequest[]> {
        return await this.claimRequestRepository.getOnHoldClaimRequests(countryCode);
    }
}
