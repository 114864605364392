import { UseCase } from '@barkibu/noma-commons';
import { Pet } from '../../domain/entities/Pet/Pet';
import { PetRepository } from '../../domain/repositories';
export class FindPetByPolicyNumber implements UseCase<Pet> {
    constructor(private petRepository: PetRepository) {}

    execute(policyIdentifier: string): Promise<Pet | undefined> {
        try {
            return this.petRepository.findByPolicyNumber(policyIdentifier);
        } catch (e) {
            throw new Error(`Pet not found for policy ${policyIdentifier}`);
        }
    }
}
