import { UseCase, PetRepository } from '@barkibu/noma-commons';
import { IncidentClaimRequest } from '../../domain/entities/IncidentClaimRequest';
import { IncidentDetails } from '../../domain/entities/IncidentDetails';
import { IncidentRepository } from '../../domain/repositories/IncidentRepository';

export class FindIncidentDetails implements UseCase<IncidentDetails> {
    constructor(
        private incidentRepository: IncidentRepository,
        private petRepository: PetRepository
    ) {}

    async execute(claimRequestKey: IncidentClaimRequest['key']): Promise<IncidentDetails> {
        try {
            const incidentClaimRequest = await this.incidentRepository.findIncidentClaimRequest(claimRequestKey);
            const pet = incidentClaimRequest.policyIdentifier
                ? await this.petRepository.findByPolicyNumber(incidentClaimRequest.policyIdentifier)
                : undefined;

            const policy = pet?.getPolicyByIdentifier(incidentClaimRequest.policyIdentifier!);
            const userReservation = incidentClaimRequest.userReservation;
            return new IncidentDetails(
                incidentClaimRequest.incident,
                incidentClaimRequest.incident!.supportingDocuments,
                pet,
                policy,
                userReservation,
                incidentClaimRequest.status,
                incidentClaimRequest.countryCode
            );
        } catch (e) {
            throw new Error('Incident not found');
        }
    }
}
