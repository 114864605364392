import { PetNomaRepository } from '../infraestructure/repositories/pet/PetNomaRepository';
import { FindPetByPolicyNumber } from './FindPetByPolicyNumber/FindPetByPolicyNumber';

const petNomaRepository = new PetNomaRepository();

const findPetByPolicyNumber = new FindPetByPolicyNumber(petNomaRepository);

export const usePet = () => {
    const findPetInformationByPolicyIdentifier = async (policyIdentifier: string) => {
        return await findPetByPolicyNumber.execute(policyIdentifier);
    };

    return {
        findPetByPolicyIdentifier: findPetInformationByPolicyIdentifier,
    };
};
