import { useGetOnHoldClaimRequest } from './useGetOnHoldClaimRequests';
import { UseAsyncStatus } from '../../../../domain/utils';
import { useHref, useQueryParams } from '../../../hooks';
import { usePet } from '../../../../application/usePet';
import { BlockingLoader } from '../../../molecules';
import { ClaimRequestsList } from '../../../organisms';

const COUNTRY_CODE_PARAM = 'countryCode';
const OnHoldListPage = () => {
    const { toDataEntry } = useHref();

    const { getQueryParamValue } = useQueryParams();
    const countryCode = getQueryParamValue(COUNTRY_CODE_PARAM) || '';
    const { onHoldClaimRequest, onHoldClaimRequestFetchStatus } = useGetOnHoldClaimRequest(countryCode);
    const { findPetByPolicyIdentifier } = usePet();
    return (
        <section className={'on-hold-list-page-container'}>
            {onHoldClaimRequestFetchStatus == UseAsyncStatus.SUCCESS ? (
                <ClaimRequestsList
                    claimRequests={onHoldClaimRequest!!}
                    goToDetail={toDataEntry}
                    getPetInformation={findPetByPolicyIdentifier!!}
                ></ClaimRequestsList>
            ) : (
                <BlockingLoader />
            )}
        </section>
    );
};
export { OnHoldListPage };
