import { ClaimRequestStatus, CountryCode, Policy, User, Pet } from '@barkibu/noma-commons';
import { Incident } from './Incident';
import { SupportingDocument } from './supporting-document';

export class IncidentDetails {
    incidentKey?: Incident['key'];
    date: Incident['date'];
    description?: Incident['description'];
    supportingDocuments?: SupportingDocument[];
    petName?: Pet['name'];
    petBirth?: Pet['birth'];
    petChip?: Pet['chip'];
    petBreed?: Pet['breed'];
    petGender?: Pet['gender'];
    petPhoto?: Pet['photo'];
    productType?: Policy['productType'];
    policyStatus?: Policy['status'];
    policyIdentifier?: Policy['identifier'];
    policyStartDate?: Policy['startDate'];
    policyExpirationDate?: Policy['expirationDate'];
    status: {
        details?: string[];
        description: ClaimRequestStatus;
    };
    countryCode?: CountryCode;
    petPolicies?: Pet['policies'];
    policy?: Policy;
    userReservation?: User;

    constructor(
        private incident?: Incident,
        supportingDocuments?: SupportingDocument[],
        private pet?: Pet,
        policy?: Policy,
        userReservation?: User,
        status?: {
            details?: string[];
            description: ClaimRequestStatus;
        },
        countryCode?: string | undefined
    ) {
        this.policy = policy;
        this.incidentKey = incident?.key;
        this.date = incident?.date;
        this.description = incident?.description;
        this.petName = pet?.name;
        this.petBirth = pet?.birth;
        this.petChip = pet?.chip;
        this.petBreed = pet?.breed;
        this.petGender = pet?.gender;
        this.petPhoto = pet?.photo;
        this.productType = policy?.productType;
        this.policyStatus = policy?.status;
        this.policyIdentifier = policy?.identifier;
        this.policyStartDate = policy?.startDate;
        this.policyExpirationDate = policy?.expirationDate;
        this.supportingDocuments = supportingDocuments;
        this.status = status ?? { description: ClaimRequestStatus.CREATED };
        this.countryCode = countryCode ? CountryCode[countryCode as keyof typeof CountryCode] : undefined;
        this.petPolicies = pet?.policies;
        this.userReservation = userReservation;
    }

    get readOnly(): boolean {
        return (
            this.status.description === ClaimRequestStatus.READY_FOR_VET_ANALYSIS ||
            this.status.description === ClaimRequestStatus.CLOSED
        );
    }

    changePolicy(policyIdentifier: string): IncidentDetails {
        const selectedPolicy = this.petPolicies?.find((policy) => policy.identifier === policyIdentifier);
        return new IncidentDetails(
            this.incident,
            this.supportingDocuments,
            this.pet,
            selectedPolicy,
            this.userReservation,
            this.status,
            this.countryCode
        );
    }

    updateSupportingDocuments(supportingDocuments: SupportingDocument[]): IncidentDetails {
        return new IncidentDetails(
            this.incident,
            supportingDocuments,
            this.pet,
            this.policy,
            this.userReservation,
            this.status,
            this.countryCode
        );
    }
}
