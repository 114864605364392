import { useState, useEffect, useRef } from 'react';
import { Chip, ChipType, NoteInput, GroupedData, isGroupedData, Option } from '../../atoms';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import {
    MissingInfoCategory,
    MissingInfoCategoryDetail,
    MissingInfoCategoryWithDetails,
    SupportingDocumentType,
    TemplateTag,
} from '../../../domain/values';
import { ClaimRequestType } from '../../../domain/entities';
import { defaultCountryCode } from '../../../domain/values/CountryCode';
import './missing-info-category-selector.modules.scss';
import TemplateInput from '../../organisms/template-input/TemplateInput';

export interface MissingInfoCategorySelectorProps {
    missingInfoCategoriesAlreadySelected: MissingInfoCategory[];
    onMissingInfoCategorySelectedHandler: (missingInfoCategoryWithDetails: MissingInfoCategoryWithDetails) => void;
    onMissingInfoCategoryRemoveHandler: (missingInfoCategory: MissingInfoCategory) => void;
    options: SupportingDocumentType[];
    claimRequestType: ClaimRequestType;
    countryCode?: string;
    petName?: string;
}

const MissingInfoCategorySelector = ({
    missingInfoCategoriesAlreadySelected,
    onMissingInfoCategorySelectedHandler,
    onMissingInfoCategoryRemoveHandler,
    options,
    claimRequestType,
    countryCode = defaultCountryCode,
    petName,
}: MissingInfoCategorySelectorProps) => {
    const [selectedCategoryWithDetails, setSelectedCategoryWithDetails] =
        useState<MissingInfoCategoryWithDetails | null>(null);
    const [manualTemplateText, setManualTemplateText] = useState('');
    const [manualTemplateSelected, setManualTemplateSelected] = useState(false);
    const { t } = useModuleTranslation();
    const defaultMissingInfoCategories = () => {
        if (missingInfoCategoriesAlreadySelected.length > 0) {
            return options
                .map((option) => option as MissingInfoCategory)
                .filter((it) => !missingInfoCategoriesAlreadySelected.includes(it))
                .filter((it) => {
                    const hasOtherSelected = missingInfoCategoriesAlreadySelected.some(
                        (selected) => selected && selected !== SupportingDocumentType.VET_FORM
                    );
                    return !(hasOtherSelected && it === SupportingDocumentType.VET_FORM);
                });
        } else {
            return options.map((option) => option as MissingInfoCategory);
        }
    };

    const templatesGrouped = t(`${claimRequestType.toString().toLowerCase()}.templates`, {
        ns: 'missing_info',
        lng: countryCode ? countryCode.toLowerCase() : defaultCountryCode.toLowerCase(),
        returnObjects: true,
        petName,
    }) as GroupedData<string | GroupedData<string>>[];

    const getTemplatesBySupportingDocument = (
        category: MissingInfoCategory
    ): Option<string | GroupedData<string>>[] => {
        const result = templatesGrouped.find((it) => it.group.toUpperCase() == category);

        if (!result) {
            return templatesGrouped.flatMap((it) => it.options);
        }
        return result.options;
    };

    const previousMissingInfoCategoryWithDetailsSelected = useRef<MissingInfoCategoryWithDetails | null>(null);
    const [missingInfoOptions, setMissingInfoOptions] = useState<MissingInfoCategory[]>(defaultMissingInfoCategories());
    const [missingInfoTemplates, setMissingInfoTemplates] = useState<Option<string | GroupedData<string>>[]>([]);
    const [templatesSelected, setTemplatesSelected] = useState<Option<string | GroupedData<string>>[]>([]);

    useEffect(() => {
        if (selectedCategoryWithDetails && selectedCategoryWithDetails.category) {
            setMissingInfoOptions([selectedCategoryWithDetails.category]);
            onMissingInfoCategorySelectedHandler(selectedCategoryWithDetails);
        } else if (previousMissingInfoCategoryWithDetailsSelected.current) {
            setMissingInfoOptions(defaultMissingInfoCategories());
            if (previousMissingInfoCategoryWithDetailsSelected.current) {
                onMissingInfoCategoryRemoveHandler(previousMissingInfoCategoryWithDetailsSelected.current.category);
            }
        }
    }, [selectedCategoryWithDetails]);

    useEffect(() => {
        if (selectedCategoryWithDetails && selectedCategoryWithDetails.category) {
            setMissingInfoOptions([selectedCategoryWithDetails.category]);
        } else if (previousMissingInfoCategoryWithDetailsSelected.current) {
            setMissingInfoOptions(defaultMissingInfoCategories());
        }
    }, [missingInfoCategoriesAlreadySelected]);

    const handleMissingInfoClicked = (description) => {
        const missingInfoCategoryDescription = description as MissingInfoCategory;

        if (selectedCategoryWithDetails?.category === missingInfoCategoryDescription) {
            setSelectedCategoryWithDetails((prevState) => {
                previousMissingInfoCategoryWithDetailsSelected.current = prevState;
                return null;
            });
            setMissingInfoTemplates([]);

            setMissingInfoOptions((prevOptions) => {
                return defaultMissingInfoCategories().filter(
                    (category) => !selectedCategoryWithDetails?.values.some((detail) => detail.label === category)
                );
            });
        } else {
            setSelectedCategoryWithDetails((prevState) => {
                previousMissingInfoCategoryWithDetailsSelected.current = prevState;
                return {
                    category: missingInfoCategoryDescription,
                    values: [],
                };
            });
            setMissingInfoTemplates(getTemplatesBySupportingDocument(missingInfoCategoryDescription));

            setMissingInfoOptions([missingInfoCategoryDescription]);
        }
    };

    const handleCategoryDetailUpdate = (template: Option<string | GroupedData<string>>) => {
        if (typeof template.value === 'string') {
            const missingInfoDetail: MissingInfoCategoryDetail = {
                label: template.label,
                value: template.value,
                missingInformationRequestedType: template.type,
            };

            setSelectedCategoryWithDetails((prevState) => {
                if (prevState && prevState.category) {
                    const prevDetails = prevState.values || [];
                    const updatedDetails = prevDetails.some((it) => it.label === missingInfoDetail.label)
                        ? prevDetails.filter((item) => item.label !== missingInfoDetail.label)
                        : [...prevDetails, missingInfoDetail];

                    return {
                        ...prevState,
                        values: updatedDetails,
                    };
                }
                return prevState;
            });
        }
    };

    const handleTemplateToggle = (template: Option<string | GroupedData<string>>) => {
        setTemplatesSelected((prevSelectedTemplates) => {
            const isTemplateSelected = prevSelectedTemplates.some(
                (selectedTemplate) => JSON.stringify(selectedTemplate) === JSON.stringify(template)
            );

            if (isGroupedData(template.value)) {
                if (isTemplateSelected) {
                    return prevSelectedTemplates.filter(
                        (selectedTemplate) => JSON.stringify(selectedTemplate) !== JSON.stringify(template)
                    );
                } else {
                    return [...prevSelectedTemplates, template];
                }
            }

            if (typeof template.value === 'string') {
                if (isTemplateSelected) {
                    return prevSelectedTemplates.filter(
                        (selectedTemplate) => JSON.stringify(selectedTemplate) !== JSON.stringify(template)
                    );
                } else {
                    return [...prevSelectedTemplates, template];
                }
            }

            return prevSelectedTemplates;
        });

        if (typeof template.value === 'string') {
            setSelectedCategoryWithDetails((prevState) => {
                const updatedValues = prevState?.values.some((item) => item.label === template.label)
                    ? prevState.values.filter((item) => item.label !== template.label)
                    : [...(prevState?.values || []), { label: template.label, value: template.value }];

                return prevState && prevState.category ? { ...prevState, values: updatedValues } : prevState;
            });
        }
    };

    const handleManualTemplateSelection = () => {
        if (manualTemplateSelected) {
            setManualTemplateSelected(false);
            setManualTemplateText('');
            setSelectedCategoryWithDetails((prevState) => {
                if (prevState) {
                    const updatedValues = prevState.values.filter((item) => item.label !== 'Manual Template');
                    return {
                        ...prevState,
                        values: updatedValues,
                    };
                }
                return prevState;
            });
        } else {
            setManualTemplateSelected(true);
            setSelectedCategoryWithDetails((prevState) => {
                if (prevState && prevState.category) {
                    const updatedValues = [
                        ...(prevState.values || []),
                        { label: 'Manual Template', value: manualTemplateText },
                    ];
                    return {
                        ...prevState,
                        values: updatedValues,
                    };
                }
                return prevState;
            });
        }
    };

    const handleTemplateInputChange = (tagId: string, value: string, template: Option<string>) => {
        setSelectedCategoryWithDetails((prevState) => {
            if (prevState && prevState.category) {
                const updatedValues = prevState.values.map((item) => {
                    if (item.label === template.label) {
                        const updatedTags = item.tagsValues ? [...item.tagsValues] : [];
                        const existingTag = updatedTags.find((tag) => tag.id === tagId);

                        if (existingTag) {
                            existingTag.value = value;
                        } else {
                            updatedTags.push({ id: tagId, value });
                        }

                        return {
                            ...item,
                            tagsValues: updatedTags,
                        };
                    }
                    return item;
                });

                return { ...prevState, values: updatedValues };
            }
            return prevState;
        });
    };

    const handleManualTemplateTextChange = (value: string) => {
        setManualTemplateText(value);
        setSelectedCategoryWithDetails((prevState) => {
            if (prevState && prevState.category) {
                const updatedValues = prevState.values.map((item) =>
                    item.label === 'Manual Template' ? { ...item, value } : item
                );
                return {
                    ...prevState,
                    values: updatedValues,
                };
            }
            return prevState;
        });
    };

    const renderGroupedOptions = (parentName: string, groupedData: GroupedData<string>) => {
        return (
            <section className="missing-info-category-sub-question" key={groupedData.group}>
                <span className="label">{groupedData.group}</span>
                <div className="missing-info-category-selector">
                    {groupedData.options.map((option) => (
                        <div key={option.label}>
                            <Chip
                                type={ChipType.OPTION}
                                text={option.label}
                                checked={selectedCategoryWithDetails?.values
                                    .flatMap((it) => it.label)
                                    .includes(parentName + ' - ' + option.label)}
                                onClick={() =>
                                    handleCategoryDetailUpdate({
                                        label: parentName + ' - ' + option.label,
                                        value: option.value,
                                        type: option.type,
                                    })
                                }
                            />
                            {!isGroupedData(option.value) &&
                                TemplateTag.hasTags(option.value) &&
                                selectedCategoryWithDetails?.values
                                    .flatMap((it) => it.label)
                                    .includes(parentName + ' - ' + option.label) &&
                                renderTemplateInputs({
                                    label: parentName + ' - ' + option.label,
                                    value: option.value,
                                    type: option.type,
                                } as Option<string>)}
                        </div>
                    ))}
                </div>
            </section>
        );
    };

    const renderTemplateInputs = (template: Option<string>) => {
        const tags = TemplateTag.extractTags(template.value);
        return (
            <section className="missing-info-category-template-inputs" key={template.label}>
                <span className="label">{template.label}</span>
                {tags.map((tag) => (
                    <div key={template.label + tag.id} className="missing-info-category-template-inputs-container">
                        <TemplateInput
                            tagId={tag.id}
                            inputType={tag.type}
                            listable={tag.isList}
                            countryCode={countryCode ? countryCode.toLowerCase() : defaultCountryCode.toLowerCase()}
                            onUpdateTemplateInput={(combinedValue) =>
                                handleTemplateInputChange(tag.id, combinedValue, template)
                            }
                        ></TemplateInput>
                    </div>
                ))}
            </section>
        );
    };

    return (
        <>
            <section>
                <span className="label">{t(`missing_info.select_document`)}</span>
                <div className="missing-info-category-selector">
                    {missingInfoOptions.map((description) => (
                        <Chip
                            key={description}
                            type={ChipType.OPTION}
                            text={t(`missing_info.type.${description}`)}
                            checked={selectedCategoryWithDetails?.category === description}
                            onClick={() => handleMissingInfoClicked(description)}
                        />
                    ))}
                </div>
            </section>
            {templatesSelected.map((template) => (
                <section className="missing-info-category-what" key={template.label}>
                    <div className="missing-info-category-selected">
                        <span className="label">{t(`missing_info.select_issue`)}</span>
                        <div>
                            <Chip
                                key={template.label}
                                type={ChipType.OPTION}
                                text={template.label}
                                checked={true}
                                onClick={() => handleTemplateToggle(template)}
                            />
                        </div>
                        {isGroupedData(template.value) &&
                            renderGroupedOptions(template.label, template.value as GroupedData<string>)}
                        {!isGroupedData(template.value) &&
                            TemplateTag.hasTags(template.value) &&
                            renderTemplateInputs(template as Option<string>)}
                    </div>
                </section>
            ))}
            {missingInfoTemplates.length > 0 && (
                <section>
                    {templatesSelected.length == 0 ? (
                        <span className="label">{t(`missing_info.select_issue`)}</span>
                    ) : (
                        <span className="label">{t(`missing_info.anything_else`)}</span>
                    )}
                    <div className="missing-info-category-selector">
                        {missingInfoTemplates
                            .filter(
                                (option) =>
                                    !templatesSelected.some(
                                        (selectedTemplate) =>
                                            JSON.stringify(selectedTemplate) === JSON.stringify(option)
                                    )
                            )
                            .map((option) => (
                                <Chip
                                    key={option.label}
                                    type={ChipType.OPTION}
                                    text={option.label}
                                    checked={selectedCategoryWithDetails?.values
                                        .flatMap((it) => it.label)
                                        .includes(option.label)}
                                    onClick={() => handleTemplateToggle(option)}
                                />
                            ))}
                        <Chip
                            type={ChipType.OPTION}
                            text={t('missing_info.add_template_manually')}
                            checked={manualTemplateSelected}
                            onClick={handleManualTemplateSelection}
                        />
                    </div>
                </section>
            )}

            {manualTemplateSelected && (
                <section>
                    <div>
                        <span className="label">{t('missing_info.write_your_own_template')}</span>
                        <NoteInput value={manualTemplateText} onValueChange={handleManualTemplateTextChange} />
                    </div>
                </section>
            )}
        </>
    );
};

export default MissingInfoCategorySelector;
