import React, { useEffect, useState } from 'react';
import { ClaimRequestType } from '../../../domain/entities';
import {
    MainSupportingDocumentTypes,
    MissingInfoCategory,
    MissingInfoCategoryWithDetails,
    SupportingDocumentType,
} from '../../../domain/values';
import { Button, ButtonVariant, Modal, NoteInput, Divider } from '../../atoms';
import { useVetTypeform } from '../../hooks/useVetTypeform/useVetTypeform';
import { MissingInfoCategorySelector } from '../../molecules';
import { useModuleTranslation } from '../../utils/useModuleTranslation';
import './mark-incident-missing-info-status-modal.scss';
import PreviewModal from '../preview-modal/PreviewModal';
import { TagValue } from '../../../domain/values/TagValue';

export interface MarkIncidentMissingStatusModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (missingInfoCategoriesWithDetails: MissingInfoCategoryWithDetails[]) => void;
    claimRequestType?: ClaimRequestType;
    countryCode?: string;
    petName?: string;
    claimRequestKey?: string;
}

const MarkIncidentMissingInfoStatusModal = ({
    isOpen,
    onClose,
    onSave,
    claimRequestType = ClaimRequestType.HEALTH,
    countryCode,
    petName,
    claimRequestKey,
}: MarkIncidentMissingStatusModalProps) => {
    const { t } = useModuleTranslation();
    const { replaceVetTypeformTokens, getVetFormTag, getPrintableVetFormLink } = useVetTypeform();
    const defaultMissingInfoOptions = [...Object.values(MainSupportingDocumentTypes)];
    const [missingInfoCategoriesWithDetails, setMissingInfoCategoriesWithDetails] = useState<
        MissingInfoCategoryWithDetails[]
    >([]);
    const [categorySelectors, setCategorySelectors] = useState<number[]>([1]);
    const isPreviousOptionSelected = () => {
        return categorySelectors.length < missingInfoCategoriesWithDetails.length + 1;
    };
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const handlePreviewModalClose = () => setShowPreviewModal(false);

    const handleAddCategorySelector = () => {
        setCategorySelectors((prev) => [...prev, prev.length + 1]);
    };

    const handleClose = () => {
        setMissingInfoCategoriesWithDetails([]);
        setCategorySelectors([0]);
        onClose();
    };

    const templateVetReportPostProcessing = (text: string, claimRequestKey: string, countryCode: string) => {
        const vetReportTokens = new Map([
            [
                '${{vetForm}}',
                getVetFormTag(
                    t('vet_form.survey_label', {
                        ns: 'missing_info',
                        lng: countryCode.toLowerCase(),
                    }),
                    claimRequestKey,
                    countryCode
                ),
            ],
            [
                '${{printableVetForm}}',
                getPrintableVetFormLink(
                    t('vet_form.printable_label', {
                        ns: 'missing_info',
                        lng: countryCode.toLowerCase(),
                    }),
                    countryCode
                ),
            ],
        ]);
        return replaceVetTypeformTokens(text, vetReportTokens);
    };

    const processCategoryDetails = (categories: MissingInfoCategoryWithDetails[]) => {
        return categories.map((category) => {
            const updatedValues = category.values.map((detail) => {
                if (detail.tagsValues) {
                    const updatedValue = replaceTagsInValue(detail.value, detail.tagsValues);
                    return {
                        ...detail,
                        value: updatedValue,
                    };
                }
                return detail;
            });

            return {
                ...category,
                values: updatedValues,
            };
        });
    };

    const handleSave = () => {
        if (
            missingInfoCategoriesWithDetails.map((it) => it.category).includes(SupportingDocumentType.VET_FORM as never)
        ) {
            const processedArray = missingInfoCategoriesWithDetails.map((item) => ({
                ...item,
                values: item.values.map((detail) => ({
                    ...detail,
                    value: templateVetReportPostProcessing(detail.value, claimRequestKey!, countryCode!),
                })),
            }));

            onSave(processedArray);
        } else {
            onSave(processCategoryDetails(missingInfoCategoriesWithDetails));
        }
    };

    const handleMissingInfoDetailsRemove = (missingInfoCategory: MissingInfoCategory) => {
        setMissingInfoCategoriesWithDetails((prevState) =>
            prevState.filter((item) => item.category !== missingInfoCategory)
        );
    };

    const handleMissingInfoDetailsSelection = (newCategoryWithDetails: MissingInfoCategoryWithDetails) => {
        setMissingInfoCategoriesWithDetails((prevState) => {
            const categoryExists = prevState.some((it) => it.category === newCategoryWithDetails.category);

            if (categoryExists) {
                return prevState.map((it) =>
                    it.category === newCategoryWithDetails.category ? newCategoryWithDetails : it
                );
            } else {
                return [...prevState, newCategoryWithDetails];
            }
        });
    };

    const replaceTagsInValue = (value: string, tagsValues: TagValue[]) => {
        return tagsValues.reduce((updatedValue, tag) => {
            const tagRegex = new RegExp(`\{\{${tag.id};.*?\}\}`, 'g'); // Crear la regex con el id dinámico
            return updatedValue.replace(tagRegex, tag.value); // Reemplazar el tag con el valor
        }, value);
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={handleClose}
                title={t('missing_info.title')}
                className="update-incident-status-modal"
            >
                <div className="update-incident-status-modal__body">
                    <div className="update-incident-status-modal__content">
                        <div className="missing-info-selector-container">
                            {countryCode &&
                                petName &&
                                categorySelectors.map((selectorIndex) => (
                                    <MissingInfoCategorySelector
                                        key={selectorIndex}
                                        missingInfoCategoriesAlreadySelected={missingInfoCategoriesWithDetails.flatMap(
                                            (it) => it.category
                                        )}
                                        onMissingInfoCategorySelectedHandler={handleMissingInfoDetailsSelection}
                                        onMissingInfoCategoryRemoveHandler={handleMissingInfoDetailsRemove}
                                        options={defaultMissingInfoOptions}
                                        claimRequestType={claimRequestType!}
                                        countryCode={countryCode}
                                        petName={petName}
                                    />
                                ))}
                        </div>
                    </div>
                    <div className="update-incident-status-modal__actions">
                        <Button
                            text={t('missing_info.add_another_category')}
                            onClick={handleAddCategorySelector}
                            disabled={
                                missingInfoCategoriesWithDetails.length > defaultMissingInfoOptions.length ||
                                !isPreviousOptionSelected() ||
                                missingInfoCategoriesWithDetails.some(
                                    (it) => it.category == SupportingDocumentType.VET_FORM
                                )
                            }
                            variantName={ButtonVariant.BASIC_INFO}
                        />
                        <Button
                            disabled={
                                missingInfoCategoriesWithDetails.flatMap((it) => it.values).length == 0 ||
                                missingInfoCategoriesWithDetails.some((it) => it.values.length === 0)
                            }
                            text={t('missing_info.save')}
                            accessibilityText={t('missing_info.save_description')}
                            onClick={() => setShowPreviewModal(true)}
                        />
                    </div>
                </div>
            </Modal>
            <PreviewModal
                isOpen={showPreviewModal}
                onClose={handlePreviewModalClose}
                onSave={handleSave}
                previewItems={missingInfoCategoriesWithDetails.map((item): { title: string; content: string[] } => ({
                    title: t(`missing_info.type.${item.category}`),
                    content: item.values.map((v) => {
                        if (v.tagsValues) {
                            return replaceTagsInValue(v.value, v.tagsValues);
                        }
                        return v.value;
                    }),
                }))}
            />
        </>
    );
};

export default MarkIncidentMissingInfoStatusModal;
